body {
  margin: 0;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

:root {
  font-size: 10px;
}
